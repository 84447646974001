.CallToActionButton {
	.action-button {
		.btn-outline-dark {
			align-items: center;
			background: transparent;
		}

		// .big {
		// 	width: 180px;
		// 	height: 65px;
		// 	font-size: 20px;
		// 	font-weight: 600;
		// }
	}

	.btn-outline-dark:hover {
		border: 1px solid #fff;
		background: #343a40;
	}

	@media (max-width: 382px) {
		height: 400px;

		.CTA-C-Button .action-button {
			text-align: center;
			margin: 0 0 20px 0;
		}
	}
}
