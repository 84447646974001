html,
body {
	max-width: 100%;
	overflow-x: hidden;
}
.app {
	margin: 0;
}
.form-switch {
	padding-left: 0 !important;
}