.Footer {
	background: #333;
	color: #fff;
	padding: 3em;
}
.title {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 1.7rem;
	font-weight: 600;
}
.list {
	list-style: none;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	// margin: 6px;
}
.list a:hover {
	color: #cd201f;
	text-decoration: none;
}
.Col-copyright .copyright {
	width: 370px;
}
.copyright span {
	color: #cd201f;
}
.copyright-lang {
	display: flex;
	justify-content: center;
	align-items: center;
}
.lang-Button {
	padding-top: 15px;
}
.lang-Button .langSwitch {
	background-color: #333;
	border: none;
	color: white;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 11px;
	margin: 4px 2px;
	transition-duration: 0.4s;
	cursor: pointer;
	color: black;
}
.button-style {
	background-color: white;
	color: black;
	border: 2px solid #555555;
}
.button-style:hover {
	// background-color: #ff0000;
	color: white;
}
.lang-Button svg {
	font-size: 1.5rem;
}
.Footer .Footer-container img {
	width: 130px;
	height: 130px;
}
.Footer .Footer-container .slogan {
	padding-top: 25px;
	color: #fff;
	// color: rgb(255, 35, 35);
	font-weight: 600;
}
.Footer .Footer-container .slogan span {
	color: #cd201f;
}
.Footer-container .copyright {
	color: #fff;
	padding-top: 20px;
}
.Footer a {
	color: #fff;
}
.Footer-container .f-col-1 {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.Footer-container .f-col-2 {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	// padding-top: 25px;
}
.Footer-container .f-col-2 .title {
	padding-top: 50px;
	margin: 0;
}
.Footer .Footer-container > *:last-child {
	background: #444;
	grid-column: 1 / span 4;
	padding: 0.5rem;
	text-align: center;
	font-size: 0.75rem;
}
.Footer-container .contact {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 50px;
}
.container .socials {
	display: flex;
	justify-content: center;
	font-size: 1.5rem;
	color: #fff;
}
.container .socials .faf {
	margin-right: 30px;
}
.container .socials .faf:hover {
	color: #4267b2;
	text-align: center;
}
.container .socials .fay:hover {
	color: #cd201f;
	text-align: center;
}
