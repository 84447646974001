#header {
	background: #e0e0e0;
	.container {
		padding: 0;
	}
	.product-section {
		max-width: 1140px;
		min-height: 550px;
		align-items: center;
		text-align: center;
		font-family: Arial, Helvetica, sans-serif;
		font-size: 20px;
		margin: 0;

		.product-logo {
			img {
				max-width: 180px;
				max-height: 180px;
			}
			.productDescription {
				text-align: left;
			}
			.productDescription b {
				font-size: 23px;
				font-weight: 600;
			}
			.productDescription br {
				padding-right: 15px;
			}
		}

		.product-pic {
			img {
				max-width: 590px;
				max-height: 320px;
				cursor: pointer;
			}
		}
	}

	@media (max-width: 382px) {
		.product-section {
			align-items: center;
			text-align: center;

			.product-logo {
				width: 380px;
				margin: 10px 15px 10px 15px;
				padding: 0;
			}

			.product-logo {
				img {
					width: 120px;
					height: 120px;
					margin-bottom: 15px;
				}
				.productDescription {
					margin: 0 15px 10px 15px;
				}
			}
			.product-pic {
				img {
					width: 340px;
					height: 180px;
				}
			}
		}
	}
	@media (max-width: 768px) {
		.product-section {
			align-items: center;
			text-align: center;

			.product-logo {
				img {
					width: 120px;
					height: 120px;
				}
				.productDescription {
					margin: 0 15px 10px 15px;
				}
			}
			.product-pic {
				img {
					width: 360px;
					height: 240px;
				}
			}
		}
	}
	@media (max-width: 1024px) {
		.product-section {
			.product-pic {
				img {
					width: 480px;
					height: 300px;
				}
			}
		}
	}
	// .App-link {
	//   color: #61dafb;
	// }

	/* @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  } */

	// .App {
	//   text-align: center;
	// }

	// .App-logo {
	//   height: 50vmin;
	//   pointer-events: none;
	// }

	// @media (prefers-reduced-motion: no-preference) {
	//   .App-logo {
	//     animation: App-logo-spin infinite 2s linear;
	//   }
	// }

	// .App-header {
	//   background-color: #282c34;
	//   min-height: 100vh;
	//   display: flex;
	//   flex-direction: column;
	//   align-items: center;
	//   justify-content: center;
	//   font-size: calc(10px + 2vmin);
	//   color: white;
	//   font: 900;
	//   font-family: "Times New Roman", Times, serif;
	// }
}
