.CallToActionSection {
	height: 200px;
	display: flex;
	justify-content: center;
	text-align: center;
	background: #e0e0e0;
	margin-top: 64px;
	.CTA-C-Text {
		margin-top: 4rem;
	}
	.CTA-C-Button {
		margin-top: 4rem;
	}
	.CTA-S-Title {
		text-align: center;
		font-size: 28px;
	}
	.CTA-S-Text {
		text-align: center;
		font-size: 26px;
	}
}
@media (max-width: 382px) {
	.CallToActionSection {
		height: 400px;

		.CTA-S-Text {
			margin: 0;
		}
	}
}
