// .Services {
// 	background: #e0e0e0;
// 	height: 550px;
// }
.serviceCard {
	background: #fff;
	height: 20rem;
	margin: 5px 5px 5px 5px;
	padding: 50px 25px 50px 25px;
	box-shadow: 0 0 4px 0 rgba(21, 22, 22, 0.6);
}
.serviceCard:hover {
	box-shadow: 0 0 5px 0 rgba(21, 22, 22, 0.9);
}
.title {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 2rem;
	font-weight: 600;
}
.servicesImg {
	width: 70px;
	height: 70px;
}
.serviceName {
	margin-top: 1rem;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 1.25rem;
	font-weight: 500;
}
.serviceDes {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 0.875rem;
	font-weight: 400;
}
