article {
	width: 100%;
	padding-bottom: 3rem;
	max-width: 1140px;
	margin: 0 auto;
	position: relative;
}
.title {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 2rem;
	font-weight: 600;
}
table {
	border-collapse: collapse;
	table-layout: fixed;
	width: 100%;
}
th {
	background: #f5f5f5;
	display: none;
}
td,
th {
	height: 53px;
}
td,
th {
	border: 1px solid #ddd;
	padding: 10px;
	empty-cells: show;
}
td,
th {
	text-align: left;
}
td + td,
th + th {
	text-align: center;
	display: none;
}
td.default {
	display: table-cell;
}
.sep {
	background: #f5f5f5;
	font-weight: bold;
}
.tick {
	font-size: 18px;
	color: #2ca01c;
}
.cross {
	font-size: 22px;
	color: #cd201f;
	font-weight: bold;
}
.hide {
	border: 0;
	background: none;
}

@media (min-width: 375px) {
	article {
		padding: 0 15px 30px 15px;
	}
	td,
	th {
		display: table-cell !important;
	}
	td,
	th {
		width: 110px;
	}
	td + td,
	th + th {
		width: 110px;
	}
}
